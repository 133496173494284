<template>
  <div class="page flex_direction_column">
    <div class="title flex">用户注册</div>
    <div class="form">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="昵称" prop="nick_name">
          <el-input placeholder="请起一个好听的昵称" v-model="ruleForm.nick_name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input placeholder="请输入手机号" v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="手机验证" prop="code">
          <el-input placeholder="请输入验证码" v-model="ruleForm.code"></el-input>
          <div class="send" :class="{ sendActive: isSend }" @click="sendCode">
            {{ isSend ? '短信已发送' : '发送验证码' }}
          </div>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input show-password placeholder="请输入6位及以上数字" v-model="ruleForm.password"></el-input>
        </el-form-item>
      </el-form>
      <div class="agre flex">
        <div class="check" @click="isCheck = !isCheck">
          <img v-if="isCheck" src="@/assets/image/icon/13.png" />
        </div>
        <div class="desc flex_wrap" @click="isClause = !isClause">
          <span>我已阅读并同意遵守</span>
          <p>《江苏省田径赛事平台注册条款》</p>
        </div>
      </div>
      <div class="box flex_space_between">
        <span @click="registerClick">注册</span>
        <span @click="$router.push('/account-login')">返回登录</span>
      </div>
    </div>
    <ClausePopup
      title="注册条款"
      :html="$store.state.config.user_treaty"
      :isClause="isClause"
      @close="closeClause"
    ></ClausePopup>
  </div>
</template>

<script>
import ClausePopup from '@/components/popup/clause.vue'
import { phoneReg } from '@/utils/regular.js'
import { postReg, getMobileCode } from '@/service/api/user.js'
const validatePhone = (rule, value, callback) => {
  phoneReg(value)
    .then(() => {
      callback()
    })
    .catch((err) => {
      return callback(new Error(err))
    })
}
export default {
  components: { ClausePopup },
  data() {
    return {
      isSend: false,
      isCheck: false,
      isClause: false,
      ruleForm: {
        code: '',
        mobile: '',
        password: '',
        nick_name: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '长度在 6 位及以上数字', trigger: 'blur' }
        ],
        code: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        nick_name: [{ required: true, message: '请输入昵称', trigger: 'blur' }]
      },
      codeTime: 60
    }
  },

  mounted() {},

  methods: {
    // 注册用户
    async postReg() {
      const res = await postReg(this.ruleForm)
      if (res.code == 1) return this.$message.success('注册成功'), this.$router.push('/account-login')
      this.$message.error(res.msg)
    },
    // 获取验证码
    async getMobileCode() {
      const res = await getMobileCode({
        mobile: this.ruleForm.mobile
      })
      if (res.code == 1) {
        var timer = null
        this.isSend = !this.isSend
        timer = setInterval(() => {
          this.codeTime = this.codeTime - 1
          if (this.codeTime == 0) {
            this.codeTime = 60
            this.isSend = !this.isSend
            clearInterval(timer)
          }
        }, 1000)
      } else {
        this.$message.error(res.msg)
      }
    },
    sendCode() {
      if (this.ruleForm.mobile == '') return this.$message.warning('请填写手机号')
      phoneReg(this.ruleForm.mobile).then(() => {
        // var time = 60
        // var timer = null
        if (!this.isSend) {
          // 获取验证码
          this.getMobileCode()
          // this.isSend = !this.isSend
        } else {
          this.$message.warning(`${this.codeTime}后可重新发送`)
        }
      })
    },
    registerClick() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isCheck) {
            this.postReg()
          } else {
            this.$message.warning('请勾选协议')
          }
        } else {
          return false
        }
      })
    },
    closeClause() {
      this.isClause = false
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../css/common.less');
/deep/ .el-form-item__label {
  font-size: 16px;
}
.page {
  align-items: center;
  justify-content: center;
  .title {
    width: 404px;
    height: 37px;
    line-height: 37px;
    font-size: @font_size_26;
    &::before {
      width: 1px;
      content: '';
      height: 40px;
      margin-right: 14px;
      background-color: #ccc;
    }
  }
  .form {
    width: 404px;
    .agre {
      margin-top: 51px;
      .check {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #ddd;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .desc {
        line-height: 20px;
        font-size: @font_size_14;
        span {
          height: 20px;
          margin-left: 10px;
        }
        p {
          cursor: pointer;
          color: #2e64ce;
          margin-left: 5px;
          display: inline-block;
        }
      }
    }
  }
}
</style>
